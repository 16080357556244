import React, { useState, useEffect } from "react";
import { ArrowBigRightDash } from "lucide-react";
import { MDBCarousel, MDBCarouselItem } from "mdb-react-ui-kit";
import Swal from "sweetalert2";
import emailjs from "emailjs-com";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import Logo from "../assets/images/logo.png";
import ProfileAvatar from "../assets/images/girl_avatar.jpg";
import "./LandingPage.css";
import { Link } from "react-router-dom";

const StudentLandingPage = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    nationality: "",
    profession: "",
    email: "",
    phoneNumber: "",
    cityOfResidence: "",
    comments: "",
    emailError: false,
    emailMessage: "",
  });
  const [phoneError, setPhoneError] = useState(false);

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const validateEmail = (email) => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });

    if (e.target.id === "email") {
      if (!validateEmail(e.target.value)) {
        setFormData((prevData) => ({
          ...prevData,
          emailError: true,
          emailMessage: "Invalid email",
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          emailError: false,
          emailMessage: "",
        }));
      }
    }
  };

  const validatePhoneNumber = (input_str) => {
    const re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
    return re.test(input_str);
  };

  const handlePhoneChange = (value) => {
    setFormData({ ...formData, phoneNumber: value });
    if (!validatePhoneNumber(value)) {
      setPhoneError(true);
    } else {
      setPhoneError(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Submitting form...");

    if (phoneError || !validatePhoneNumber(formData.phoneNumber)) {
      setPhoneError(true);
      return;
    }
    if (!validateEmail(formData.email)) {
      setFormData((prevData) => ({
        ...prevData,
        emailError: true,
        emailMessage: "Invalid email",
      }));
      return;
    }

    // Send email using EmailJS
    emailjs
      .send(
        "service_z8cr3lf",
        "template_b6l6e0k",
        {
          fullName: formData.fullName,
          nationality: formData.nationality,
          profession: formData.profession,
          email: formData.email,
          phoneNumber: formData.phoneNumber,
          cityOfResidence: formData.cityOfResidence,
          comments: formData.comments,
          to_name: formData.fullName,
          to_email: formData.email,
        },
        "IpakiaUw3rbKMdmV6"
      )
      .then(
        (result) => {
          console.log("Email sent successfully: ", result.text);
          Swal.fire({
            icon: "success",
            title: "Successful",
            text: "Our team will shortly get in touch with you for further discussion. Please Check your Email and see the investor packages",
          });

          // Optional: Reset form fields after successful submission
          setFormData({
            fullName: "",
            nationality: "",
            profession: "",
            email: "",
            phoneNumber: "",
            cityOfResidence: "",
            comments: "",
            emailError: false,
            emailMessage: "",
          });
        },
        (error) => {
          console.error("EmailJS Error: ", error.text);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to submit form. Please try again.",
          });
        }
      );
  };

  return (
    <div>
      {/* 1. Hero Section with Background Image */}
      <div className="container">
        <div className="row g-0">
          <div className="col-12">
            <div className="text-center mt-5 mt-lg-5 pt-3 pt-lg-5">
              <img src={Logo} className="logo_img" alt="logo" />
              <p className="first_section_p text-dark pt-3 pt-lg-5">
                Welcome to the
              </p>
              <p className="first_section_p_1 text-white rounded-3 pt-4 pb-4 m-0">
                Command & Staff College of Physicians & Surgeons
              </p>
              <p className="first_section_p_2 text-dark mt-5">
                Anyone, anywhere can now become a medical doctor through our
                world class American Program,
              </p>
              <h5 className="first_section_h5 text-uppercase text-dark fw-bold">
                THE AI ENVIRONMENT & MEDICAL SCIENTIST TRAINING PROGRAM
              </h5>
            </div>
          </div>
        </div>
      </div>

      {/* 3. Custom Video */}
      <section className="video-section bg-light mt-lg-5 p-0">
        <div className="container-fluid p-0 m-0 text-center pt-3 pb-3 pb-lg-5">
          <h2 className="section_section_h2 text-capitalize text-dark mt-3">
            Watch our full video
          </h2>
          <div className="row mt-3 g-0">
            <div className="col-12">
              <div
                style={{
                  position: "relative",
                  paddingBottom: "56.25%",
                  height: 0,
                  overflow: "hidden",
                  maxWidth: "100%",
                }}
              >
                <iframe
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  src="https://www.youtube.com/embed/puH-7nhoITE?autoplay=1&controls=0&showinfo=0&rel=0&modestbranding=1&loop=1&mute=0"
                  title="Revolutionizing Medical Education with CSCPS | Become a Leader in Medicine, AI and More"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="row g-0">
          <div className="col-12">
            <div className="text-center mt-4">
              <p className="first_section_p_1 text-white rounded-3 pt-4 pb-4 m-0">
                Become the Future Leader in Healthcare and
                <br className="d-none d-sm-block" /> Innovation at CSCPS
              </p>
              <p className="first_section_p_2 text-dark mt-4">
                "Join the revolution in medical, environmental, and
                AI-integrated education. Fast-track your career with multi-tier,
                multi-degree programs designed for the next generation of global
                leaders."
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container py-5">
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
          <div className="col">
            <div className="card h-100 shadow-sm hover-effect position-relative">
              <div className="ribbon red">
                <span>Benefit</span>
              </div>
              <div className="card-body">
                <h5 className="section_third_card_title">
                  Fast-Track Learning
                </h5>
                <p className="card-text">
                  Skip traditional years of schooling and jump straight into
                  professional programs in Medicine, Nursing, Pharmacy, and
                  more.
                </p>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card h-100 shadow-sm hover-effect position-relative">
              <div className="ribbon green">
                <span>Benefit</span>
              </div>
              <div className="card-body">
                <h5 className="section_third_card_title">
                  AI-Integrated Education
                </h5>
                <p className="card-text">
                  Learn in a cutting-edge curriculum powered by Artificial
                  Intelligence, preparing you for future healthcare and
                  environmental challenges.
                </p>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card h-100 shadow-sm hover-effect position-relative">
              <div className="ribbon blue">
                <span>Benefit</span>
              </div>
              <div className="card-body">
                <h5 className="section_third_card_title">Multiple Degrees</h5>
                <p className="card-text">
                  Graduate with essential degrees in Medicine, Environment, and
                  Business Administration (MBA), along with specialized AI
                  training.
                </p>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card h-100 shadow-sm hover-effect position-relative">
              <div className="ribbon orange">
                <span>Benefit</span>
              </div>
              <div className="card-body">
                <h5 className="section_third_card_title">Flexible Admission</h5>
                <p className="card-text">
                  Open to all ages and backgrounds. The only requirement is
                  English proficiency.
                </p>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card h-100 shadow-sm hover-effect position-relative">
              <div className="ribbon pink">
                <span>Benefit</span>
              </div>
              <div className="card-body">
                <h5 className="section_third_card_title">
                  Ph.D. Opportunities
                </h5>
                <p className="card-text">
                  Our Ph.D. labs offer direct access to advanced research and
                  leadership roles, shaping the future of global healthcare.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row g-0">
          <h2 className="section_section_h2 text-capitalize text-dark mt-3">
            How It Works:
          </h2>
          <div className="col-12">
            <ol className="m-0 ps-4">
              <li className="section_fourth_li">
                <span className="fw-bold text-capitalize">
                  Apply through our School of Admissions:
                </span>
                Each candidate is individually assessed to find the perfect
                entry point for their journey.
              </li>
              <li className="section_fourth_li">
                <span className="fw-bold text-capitalize">
                  Start Your Professional Program:
                </span>
                Our multi-tier system fast-tracks you to a professional career,
                with hands-on learning and AI-enhanced courses.
              </li>
              <li className="section_fourth_li">
                <span className="fw-bold text-capitalize">
                  Graduate as a Leader:
                </span>
                Earn your MD, Nursing, or MBA degree while specializing in
                critical areas like environment and artificial intelligence.
              </li>
            </ol>
            <Link className="text-decoration-none" to="/">
              <button className="button-33 mt-4">Call to Action</button>
            </Link>
            <p className="first_section_p_2 text-dark mt-5">
              <ArrowBigRightDash /> Start Your Journey Today—Apply Now and
              Become a Leader in Global Healthcare
            </p>
          </div>
        </div>
      </div>

      <div className="container p-3" style={{ maxWidth: "1200px" }}>
        <form
          onSubmit={handleSubmit}
          className="w-100 p-0 p-lg-4"
          style={{
            boxShadow: "0 !Important",
          }}
        >
          <div className="row g-4">
            <div className="col-md-6 col-12">
              <div className="mb-3">
                <label htmlFor="fullName" className="form-label">
                  Full Name as shown on government ID/ Passport
                  <span className="input_span">&nbsp;(Required)</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="fullName"
                  placeholder="Name"
                  value={formData.fullName}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="col-md-6 col-12">
              <div className="mb-3">
                <label htmlFor="nationality" className="form-label">
                  Nationality <span className="input_span">(Required)</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="nationality"
                  placeholder="Nationality"
                  value={formData.nationality}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="col-md-6 col-12">
              <div className="mb-3">
                <label htmlFor="profession" className="form-label">
                  Profession <span className="input_span">(Required)</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="profession"
                  placeholder="Profession"
                  value={formData.profession}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="col-md-6 col-12">
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email Address <span className="input_span">(Required)</span>
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                {formData.emailError && (
                  <div className="error pt-2" style={{ color: "#fe0000" }}>
                    Invalid email
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-6 col-12">
              <div className="mb-3">
                <label htmlFor="phoneNumber" className="form-label">
                  Phone Number <span className="input_span">(Required)</span>
                </label>
                <PhoneInput
                  country={"us"}
                  value={formData.phoneNumber}
                  onChange={handlePhoneChange}
                  inputProps={{
                    name: "phoneNumber",
                    required: true,
                    autoFocus: true,
                  }}
                />
                {phoneError && (
                  <div className="error pt-2" style={{ color: "#fe0000" }}>
                    Please enter a valid phone number
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-6 col-12">
              <div className="mb-3">
                <label htmlFor="cityOfResidence" className="form-label">
                  City of Residence
                  <span className="input_span" style={{ color: "red" }}>
                    (Required)
                  </span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="cityOfResidence"
                  placeholder="City of Residence"
                  value={formData.cityOfResidence}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="col-12">
              <div className="mb-3">
                <label htmlFor="comments" className="form-label">
                  Comments
                </label>
                <textarea
                  className="form-control"
                  id="comments"
                  rows="5"
                  value={formData.comments}
                  onChange={handleChange}
                  style={{
                    padding: "12px",
                    borderRadius: "8px",
                    border: "1px solid #ccc",
                  }}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-md-3 col-12 mt-5 mb-4">
              <button
                type="submit"
                className="button-33 w-100 rounded pt-2 pb-2"
              >
                Apply Now
              </button>
            </div>
          </div>
        </form>
      </div>

      <section className="gradient-custom">
        <div className="container my-5 py-5">
          <h2 className="section_section_h2 text-center text-capitalize text-dark mt-3">
            Reviews
          </h2>
          <div className="row d-flex justify-content-center">
            <div className="col-md-12 col-10">
              <div className="text-center mb-4 pb-2">
                <i className="fas fa-quote-left fa-3x text-white"></i>
              </div>
              <div className="card">
                <div className="card-body px-4 py-3 py-lg-5">
                  {/* Carousel */}
                  <MDBCarousel showIndicators interval={5000} fade>
                    {/* Single item 1 */}
                    <MDBCarouselItem
                      className="w-100 d-block"
                      itemId={1}
                      src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp"
                      alt="John Avatar"
                    >
                      <div className="row d-flex justify-content-center">
                        <div className="col-lg-10 col-xl-8">
                          <div className="row">
                            <div className="col-lg-4 col-12 text-center d-flex align-items-center text-md-start">
                              <img
                                src={ProfileAvatar}
                                alt="John Avatar"
                                className="rounded-4"
                                width="150"
                                height="150"
                              />
                            </div>
                            <div className="col-lg-8 col-12 text-center text-lg-start mx-auto mx-lg-0">
                              <h4 className="mb-4">
                                John (Parent of a Medical Student)
                              </h4>
                              <p className="mb-0 pb-0 pb-lg-3">
                                "CSCPS has been a life-changing experience for
                                my daughter. The integration of AI into the
                                medical curriculum is revolutionary and ensures
                                she is learning not only traditional medical
                                skills but also preparing for the future. The
                                multi-tier program allowed her to start without
                                wasting unnecessary years in traditional
                                schooling. We couldn’t be more thrilled!"
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </MDBCarouselItem>

                    {/* Single item 2 */}
                    <MDBCarouselItem
                      className="w-100 d-block"
                      itemId={2}
                      src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp"
                      alt="Sarah Avatar"
                    >
                      <div className="row d-flex justify-content-center">
                        <div className="col-lg-10 col-xl-8">
                          <div className="row">
                            <div className="col-lg-4 col-12 text-center d-flex align-items-center text-md-start">
                              <img
                                src={ProfileAvatar}
                                alt="Sarah Avatar"
                                className="rounded-4"
                                width="150"
                                height="150"
                              />
                            </div>
                            <div className="col-lg-8 col-12 text-center text-lg-start mx-auto mx-lg-0">
                              <h4 className="mb-4">Sarah (Nursing Student)</h4>
                              <p className="mb-0 pb-0 pb-lg-3">
                                "Joining CSCPS has been the best decision I ever
                                made. The flexibility of the program, combined
                                with its focus on cutting-edge technology like
                                AI, has prepared me for a career in modern
                                healthcare. I feel equipped to be a leader in my
                                field, and the support from faculty has been
                                outstanding. I highly recommend CSCPS for anyone
                                serious about making an impact in healthcare."
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </MDBCarouselItem>

                    {/* Single item 3 */}
                    <MDBCarouselItem
                      className="w-100 d-block"
                      itemId={3}
                      src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp"
                      alt="Michael Avatar"
                    >
                      <div className="row d-flex justify-content-center">
                        <div className="col-lg-10 col-xl-8">
                          <div className="row">
                            <div className="col-lg-4 col-12 text-center d-flex align-items-center text-md-start">
                              <img
                                src={ProfileAvatar}
                                alt="Michael Avatar"
                                className="rounded-4"
                                width="150"
                                height="150"
                              />
                            </div>
                            <div className="col-lg-8 col-12 text-center text-lg-start mx-auto mx-lg-0">
                              <h4 className="mb-4">
                                Michael (Parent of a Ph.D. Student)
                              </h4>
                              <p className="mb-0 pb-0 pb-lg-3">
                                "I was initially hesitant about my son taking
                                the unconventional path through CSCPS, but the
                                personalized education system has proven its
                                worth. CSCPS allows students to work on
                                real-world research and get involved in
                                innovative Ph.D. programs right from the start.
                                The fact that they integrate business
                                administration into the curriculum is
                                forward-thinking and exactly what young
                                professionals need today."
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </MDBCarouselItem>

                    {/* Single item 4 */}
                    <MDBCarouselItem
                      className="w-100 d-block"
                      itemId={4}
                      src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp"
                      alt="Emily Avatar"
                    >
                      <div className="row d-flex justify-content-center">
                        <div className="col-lg-10 col-xl-8">
                          <div className="row">
                            <div className="col-lg-4 col-12 text-center d-flex align-items-center text-md-start">
                              <img
                                src={ProfileAvatar}
                                alt="Emily Avatar"
                                className="rounded-4"
                                width="150"
                                height="150"
                              />
                            </div>
                            <div className="col-lg-8 col-12 text-center text-lg-start mx-auto mx-lg-0">
                              <h4 className="mb-4">
                                Emily (Environmental Science Student)
                              </h4>
                              <p className="mb-0 pb-0 pb-lg-3">
                                "What I love most about CSCPS is its
                                multi-disciplinary approach. I’m studying
                                environmental science but also gaining knowledge
                                in medicine and artificial intelligence, which
                                is so rare to find. This holistic education is
                                helping me look at problems in a way I never
                                imagined. CSCPS is truly revolutionizing
                                education, and I’m so proud to be part of it."
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </MDBCarouselItem>

                    {/* Single item 5 */}
                    <MDBCarouselItem
                      className="w-100 d-block"
                      itemId={5}
                      src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp"
                      alt="David Avatar"
                    >
                      <div className="row d-flex justify-content-center">
                        <div className="col-lg-10 col-xl-8">
                          <div className="row">
                            <div className="col-lg-4 col-12 text-center d-flex align-items-center text-md-start">
                              <img
                                src={ProfileAvatar}
                                alt="David Avatar"
                                className="rounded-4"
                                width="150"
                                height="150"
                              />
                            </div>
                            <div className="col-lg-8 col-12 text-center text-lg-start mx-auto mx-lg-0">
                              <h4 className="mb-4">
                                David (Parent of a Business Student)
                              </h4>
                              <p className="mb-0 pb-0 pb-lg-3">
                                "CSCPS is not just about academics; it’s about
                                building future leaders. The focus on AI,
                                business, and healthcare is groundbreaking. My
                                son is learning how to combine these fields to
                                solve real-world problems, and the personalized
                                entry process allowed him to start right where
                                he needed to. CSCPS is preparing him to be a
                                force in the future of global business."
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </MDBCarouselItem>

                    {/* Single item 6 */}
                    <MDBCarouselItem
                      className="w-100 d-block"
                      itemId={6}
                      src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp"
                      alt="Julia Avatar"
                    >
                      <div className="row d-flex justify-content-center">
                        <div className="col-lg-10 col-xl-8">
                          <div className="row">
                            <div className="col-lg-4 col-12 text-center d-flex align-items-center text-md-start">
                              <img
                                src={ProfileAvatar}
                                alt="Julia Avatar"
                                className="rounded-4"
                                width="150"
                                height="150"
                              />
                            </div>
                            <div className="col-lg-8 col-12 text-center text-lg-start mx-auto mx-lg-0">
                              <h4 className="mb-4">Julia (Medical Student)</h4>
                              <p className="mb-0 pb-0 pb-lg-3">
                                "The fact that CSCPS integrates artificial
                                intelligence into every aspect of their programs
                                is incredible. It’s not just about getting a
                                degree—it’s about becoming a part of a global
                                movement in education and healthcare. I feel
                                empowered to not only succeed in the medical
                                field but also be a pioneer in innovation. CSCPS
                                is setting the stage for the future!"
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </MDBCarouselItem>
                  </MDBCarousel>
                </div>
              </div>
              <div className="text-center mt-4 pt-2">
                <i className="fas fa-quote-right fa-3x text-white"></i>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default StudentLandingPage;
