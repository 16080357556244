import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import emailjs from "emailjs-com";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const PortalForm = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    nationality: "",
    profession: "",
    email: "",
    phoneNumber: "",
    cityOfResidence: "",
    cityOfFranchise: "",
    comments: "",
    emailError: false,
    emailMessage: "",
  });
  const [phoneError, setPhoneError] = useState(false);

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const validateEmail = (email) => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });

    if (e.target.id === "email") {
      if (!validateEmail(e.target.value)) {
        setFormData((prevData) => ({
          ...prevData,
          emailError: true,
          emailMessage: "Invalid email",
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          emailError: false,
          emailMessage: "",
        }));
      }
    }
  };

  const validatePhoneNumber = (input_str) => {
    const re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
    return re.test(input_str);
  };

  const handlePhoneChange = (value) => {
    setFormData({ ...formData, phoneNumber: value });
    if (!validatePhoneNumber(value)) {
      setPhoneError(true);
    } else {
      setPhoneError(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Submitting form...");

    if (phoneError || !validatePhoneNumber(formData.phoneNumber)) {
      setPhoneError(true);
      return;
    }
    if (!validateEmail(formData.email)) {
      setFormData((prevData) => ({
        ...prevData,
        emailError: true,
        emailMessage: "Invalid email",
      }));
      return;
    }

    // Send email using EmailJS
    emailjs
      .send(
        "service_z8cr3lf",
        "template_b6l6e0k",
        {
          fullName: formData.fullName,
          nationality: formData.nationality,
          profession: formData.profession,
          email: formData.email,
          phoneNumber: formData.phoneNumber,
          cityOfResidence: formData.cityOfResidence,
          cityOfFranchise: formData.cityOfFranchise,
          comments: formData.comments,
          to_name: formData.fullName,
          to_email: formData.email,
        },
        "IpakiaUw3rbKMdmV6"
      )
      .then(
        (result) => {
          console.log("Email sent successfully: ", result.text);
          Swal.fire({
            icon: "success",
            title: "Successful",
            text: "Our team will shortly get in touch with you for further discussion. Please Check your Email and see the investor packages",
          });

          // Optional: Reset form fields after successful submission
          setFormData({
            fullName: "",
            nationality: "",
            profession: "",
            email: "",
            phoneNumber: "",
            cityOfResidence: "",
            cityOfFranchise: "",
            comments: "",
            emailError: false,
            emailMessage: "",
          });
        },
        (error) => {
          console.error("EmailJS Error: ", error.text);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to submit form. Please try again.",
          });
        }
      );
  };

  return (
    <>
      <div className="container">
        <div className="row g-0 align-items-center justify-content-center">
          <div className="col-lg-5 col-md-5 col-sm-10">
            <form onSubmit={handleSubmit} className="mt-3">
              <div className="mb-3 w-100 mt-4">
                <label htmlFor="fullName" className="form-label">
                  Full Name as shown on government ID/ Passport
                  <span className="input_span">&nbsp;(Required)</span>
                </label>
                <input
                  type="text"
                  className="form-control w-100"
                  id="fullName"
                  placeholder="Name"
                  value={formData.fullName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3 w-100 mt-5">
                <label htmlFor="nationality" className="form-label">
                  Nationality<span className="input_span">&nbsp;(Required)</span>
                </label>
                <input
                  type="text"
                  className="form-control w-100"
                  id="nationality"
                  placeholder="Nationality"
                  value={formData.nationality}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3 w-100 mt-5">
                <label htmlFor="profession" className="form-label">
                  Profession<span className="input_span">&nbsp;(Required)</span>
                </label>
                <input
                  type="text"
                  className="form-control w-100"
                  id="profession"
                  placeholder="Profession"
                  value={formData.profession}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3 w-100 mt-5">
                <label htmlFor="email" className="form-label">
                  Email Address<span className="input_span">&nbsp;(Required)</span>
                </label>
                <input
                  type="text"
                  id="email"
                  className="form-control w-100"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                {formData.emailError && (
                  <div className="error pt-2" style={{ color: "#fe0000" }}>
                    Invalid email
                  </div>
                )}
              </div>
              <div className="mb-3 w-100 mt-5">
                <label htmlFor="phoneNumber" className="form-label">
                  Phone Number<span className="input_span">&nbsp;(Required)</span>
                </label>
                <PhoneInput
                  country={"us"}
                  value={formData.phoneNumber}
                  onChange={handlePhoneChange}
                  inputProps={{
                    name: "phoneNumber",
                    required: true,
                    autoFocus: true,
                  }}
                  containerClass="w-100"
                />
                {phoneError && (
                  <div className="error pt-2" style={{ color: "#fe0000" }}>
                    Please enter a valid phone number
                  </div>
                )}
              </div>
              <div className="mb-3 w-100 mt-5">
                <label htmlFor="cityOfResidence" className="form-label">
                  City of Residence
                  <span className="input_span">&nbsp;(Required)</span>
                </label>
                <input
                  type="text"
                  className="form-control w-100"
                  id="cityOfResidence"
                  placeholder="City of Residence"
                  value={formData.cityOfResidence}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3 w-100 mt-5">
                <label htmlFor="cityOfFranchise" className="form-label">
                  Country of Franchise
                  <span className="input_span">&nbsp;(Required)</span>
                </label>
                <input
                  type="text"
                  className="form-control w-100"
                  id="cityOfFranchise"
                  placeholder="Country of Franchise"
                  value={formData.cityOfFranchise}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3 mt-5">
                <label htmlFor="comments" className="form-label">
                  Comments
                </label>
                <textarea
                  className="form-control w-100"
                  id="comments"
                  rows="5"
                  value={formData.comments}
                  onChange={handleChange}
                ></textarea>
              </div>
              <div className="mt-5 mb-4">
                <button
                  type="submit"
                  className="eye_btn w-100 rounded p-2 ps-3 pe-3"
                >
                  SUBMIT
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PortalForm;
