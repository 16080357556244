import React from "react";
import Logo from "../../assets/images/logo.png";
import {
  faceIconFooter,
  linkIconFooter,
  instIcon,
  locateIconFooter,
} from "../../constant/svgs";
import { Mail, Phone, Youtube } from "lucide-react";
import { Link } from "react-router-dom";
const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  };
  const openEmail = () => {
    window.location.href = "mailto:contact@cscps.com";
  };
  return (
    <>
      <div className="footer_main_div">
        <div className="container">
          <div className="row g-0 align-items-center justify-content-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="row pt-5 pb-0 pb-lg-5">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="">
                    <Link
                      className="text-decoration-none "
                      to="/"
                      onClick={scrollToTop}
                    >
                      <img
                        src={Logo}
                        alt="logo"
                        width={70}
                        className="logo_shadow"
                      />
                    </Link>
                  </div>
                  <p className="footer_para mt-4">
                    CSCPS is a premier medical and allied health education
                    providing technology company with its own educational,
                    learning ,teaching, and examination systems. With multiple
                    companies in the public and private sectors, they work on a
                    SAAS (Software As A Service) model for every aspect of the
                    business, from management to examination. The company has
                    caused major disruption in the current medical education
                    systems across the world and wishes to thank all its
                    investors, partners, and the people who came in waves
                    encouraging the acceptance of the new eco system.
                  </p>
                  <div className="d-flex align-items-center gap-3 mt-4 pt-2">
                    <div>
                      <Link
                        to="https://www.facebook.com/people/Command-Staff-College-of-Physicians-and-Surgeons/61564147527069/?mibextid=ZbWKwL"
                        target="_blank"
                        className="circle-div"
                      >
                        {faceIconFooter}
                      </Link>
                    </div>
                    <div>
                      <Link
                        to="https://www.instagram.com/cscps1/"
                        target="_blank"
                        className="circle-div"
                      >
                        {instIcon}
                      </Link>
                    </div>
                    <div>
                      <Link
                        to="https://pin.it/7cRY9MQ77"
                        target="_blank"
                        className="circle-div d-flex align-items-center"
                      >
                        <h5 className="text-white p-0 m-0">P</h5>
                      </Link>
                    </div>
                    <div>
                      <Link
                        to="https://www.linkedin.com/in/command-and-staff-college-775003331/"
                        target="_blank"
                        className="circle-div"
                      >
                        {linkIconFooter}
                      </Link>
                    </div>
                    <div>
                      <Link
                        to="https://www.youtube.com/@cscps-d5t"
                        target="_blank"
                        className="circle-div"
                      >
                        <Youtube />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-6 d-none d-md-block">
                  <h4 className="ace_span pt-3">Quick Links</h4>
                  <Link
                    className="text-decoration-none"
                    to="/"
                    onClick={scrollToTop}
                  >
                    <p className="footer_para_1 mt-5 pt-2">Home</p>
                  </Link>
                  <Link
                    className="text-decoration-none"
                    to="/about"
                    onClick={scrollToTop}
                  >
                    <p className="footer_para_1">About</p>
                  </Link>
                  <Link
                    className="text-decoration-none"
                    to="/contact"
                    onClick={scrollToTop}
                  >
                    <p className="footer_para_1 cursor_pointer">Contact</p>
                  </Link>
                  <Link
                    className="text-decoration-none"
                    to="/our-companies"
                    onClick={scrollToTop}
                  >
                    <p className="footer_para_1 cursor_pointer">
                      Our Companies
                    </p>
                  </Link>
                  <Link
                    className="text-decoration-none"
                    to="/students-landing-page"
                    onClick={scrollToTop}
                  >
                    <p className="footer_para_1 cursor_pointer">Students</p>
                  </Link>
                  <Link
                    className="text-decoration-none"
                    to="/franchising-landing-page"
                    onClick={scrollToTop}
                  >
                    <p className="footer_para_1 cursor_pointer">Franchising</p>
                  </Link>
                </div>
                <div className="col-lg-2 col-md-2 col-5 d-none d-md-block">
                  <h4 className="ace_span pt-3">Locations</h4>
                  <div className="row mt-2 mt-lg-5 pt-2">
                    <div className="col-12">
                      <p className="heading_decoration_none">United States</p>
                      <div className="d-flex gap-2 align-content-center cursor_pointer">
                        <div className="d-flex align-items-start pt-1">
                          {locateIconFooter}
                        </div>
                        <p className="footer_para_2 w-75">
                          <Link
                            className="footer_para_2 text-decoration-none"
                            // to="https://maps.app.goo.gl/B3ESLSuHWy8pWb8n9"
                            // target="_blank"
                          >
                            32 A Lawrence Road, Jinnah Town, Lahore
                          </Link>
                        </p>
                      </div>
                      <p
                        onClick={openEmail}
                        className="footer_para_mail d-flex gap-2 align-items-center cursor_pointer"
                      >
                        <Mail size={16} color="white" /> contact@cscps.com
                      </p>
                      <p className="footer_para_2 d-flex align-items-center gap-2 cursor_pointer">
                        <a
                          href="tel:+16034848016"
                          className="footer_para_2 text-decoration-none d-flex gap-2"
                        >
                          <Phone size={17} color="white" />
                          0321-9700700
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-5 d-none d-md-block">
                  <h4 className="ace_span pt-3">Locations</h4>
                  <div className="row mt-2 mt-lg-5 pt-2">
                    <div className="col-12">
                      <p className="heading_decoration_none">United States</p>
                      <div className="d-flex gap-2 align-content-center cursor_pointer">
                        <div className="d-flex align-items-start pt-1">
                          {locateIconFooter}
                        </div>
                        <p className="footer_para_2 w-75">
                          <Link
                            className="footer_para_2 text-decoration-none"
                            // to="https://maps.app.goo.gl/B3ESLSuHWy8pWb8n9"
                            // target="_blank"
                          >
                            32 A Lawrence Road, Jinnah Town, Lahore
                          </Link>
                        </p>
                      </div>
                      <p
                        onClick={openEmail}
                        className="footer_para_mail d-flex gap-2 align-items-center cursor_pointer"
                      >
                        <Mail size={16} color="white" /> contact@cscps.com
                      </p>
                      <p className="footer_para_2 d-flex align-items-center gap-2 cursor_pointer">
                        <a
                          href="tel:+16034848016"
                          className="footer_para_2 text-decoration-none d-flex gap-2"
                        >
                          <Phone size={17} color="white" />
                          0321-9700700
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="footer_hr mt-3 mt-lg-0" />
        <div className="pb-2 pt-0 pt-lg-2">
          <div className="row g-0 align-items-center justify-content-center">
            <div className="col-lg-6 col-md-6 col-sm-12 text-start mt-2 my-sm-auto text-lg-center text-md-center order-2 order-md-1">
              <p className="footer_para_4 mt-3 mt-lg-0 mt-md-0 m-md-auto p-0 ms-3 ms-lg-0">
                CSCPS © 2024. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
