import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { locationContact, mailContact, callContact } from "../../constant/svgs";
import { postRequest } from "../../services/api";

const ContactPage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fullName: "",
    address: "",
    email: "",
    message: "",
    emailError: false,
    emailMessage: "",
  });

  const validateEmail = (email) => {
    var regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });

    if (e.target.id === "email") {
      if (!validateEmail(e.target.value)) {
        setFormData((prevData) => ({
          ...prevData,
          emailError: true,
          emailMessage: "Invalid email",
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          emailError: false,
          emailMessage: "",
        }));
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(formData.email)) {
      setFormData((prevData) => ({
        ...prevData,
        emailError: true,
        emailMessage: "Invalid email",
      }));
      return;
    }

    const { fullName, address, email, message } = formData;

    try {
      const payload = {
        fullName,
        address,
        email,
        message,
      };

      const res = await postRequest("/api/contactform", payload);
      const data = res.data;

      console.log(data);

      setFormData({
        fullName: "",
        address: "",
        email: "",
        message: "",
        emailError: false,
        emailMessage: "",
      });
      navigate("/");
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Form submitted successfully! Please Check your mail",
      });
    } catch (error) {
      console.error("Error submitting form:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to submit form. Please try again.",
      });
    }
  };
  
  return (
    <>
      <div className="about_main_div">
        <div className="container">
          <div className="row g-0 align-items-center justify-content-center">
            <div className="col-lg-6 col-md-5 col-sm-12">
              <div className="text-center mt-5 pt-5 pb-5 mb-5">
                <h1 className="h11_country_cscps ms-2 me-2 m-0 p-0">
                  Contact US
                </h1>
                <h1
                  className="text-white ace_span22 ms-2 me-2 m-0 p-0"
                  style={{ whiteSpace: "nowrap" }}
                >
                  command & staff college of <br /> physicians & surgeons
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row mainPage">
          <div className="col-lg-12 col-md-12 col-sm-12 pt-3 pt-md-0">
            <div className="row mt-5 pt-5 smScreen sm-py-20">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div>
                  <h1 className="ace_h1_21 p-0 m-0">
                    <span className="ace_span m-0 p-0">Contact US</span>
                  </h1>
                  <h5 className="globalizedSty my-3 text-capitalize">
                    <h4 className="ace_h4">Nashua, United States</h4>
                  </h5>
                  <div className="d-flex gap-2 ps-lg-3 ps-0">
                    <span className="me-1 d-flex align-items-start">
                      {locationContact}
                    </span>
                    <p className="p-0 m-0 contactpage_p_1 text_underlined_sm">
                    32 A Lawrence Road, Jinnah Town, Lahore
                    </p>
                  </div>
                  <div className="d-flex pt-2 gap-2 align-items-baseline ps-lg-3 ps-0">
                    <span className="me-1">{callContact}</span>
                    <p className="p-0 m-0 contactpage_p_1">0321-9700700</p>
                  </div>
                  <div className="d-flex pt-2 gap-2 align-items-baseline ps-lg-3 ps-0">
                    <span className="me-1">{mailContact}</span>
                    <p className="p-0 m-0 contactpage_p_1">contact@intel.com</p>
                  </div>
                  <div className="mt-4 border border-2 p-2 rounded-2 w-100">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2931.775838291866!2d-71.454867!3d42.708467!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e3bb2149c82daf%3A0xacb49a52cb3e0c!2sSwati%20Corporation!5e0!3m2!1sen!2sus!4v1709547884688!5m2!1sen!2sus"
                      className="maping"
                      style={{ width: "100%", height: "45vh" }}
                      allowfullscreen=""
                      loading="lazy"
                      title="map"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <form onSubmit={handleSubmit} className="mt-3">
                  <div className="mb-3 w-100 mt-4">
                    <label htmlFor="fullName" className="form-label">
                      Full Name
                      <span className="input_span"> (Required)</span>
                    </label>
                    <input
                      type="text"
                      className="form-control w-100"
                      id="fullName"
                      placeholder="Name"
                      value={formData.fullName}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="mb-3 w-100">
                    <label htmlFor="address" className="form-label">
                      Home Address
                      <span className="input_span"> (Required)</span>
                    </label>
                    <input
                      type="text"
                      className="form-control w-100"
                      id="address"
                      placeholder="Address"
                      value={formData.address}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="mb-3 w-100">
                    <label htmlFor="email" className="form-label">
                      Email
                      <span className="input_span"> (Required)</span>
                    </label>
                    <input
                      type="text"
                      id="email"
                      className="form-control w-100"
                      placeholder="Email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                    {formData.emailError && (
                      <div className="error pt-2" style={{ color: "#fe0000" }}>
                        {formData.emailMessage}
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="message" className="form-label">
                      Message
                    </label>
                    <textarea
                      className="form-control w-100"
                      id="message"
                      rows="5"
                      value={formData.message}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                  <div className="mt-5 mb-4">
                    <button type="submit" className="eye_btn w-100">
                      SUBMIT
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="row my-md-3 my-0">
          <div className="col-md-6 col-12 ">
            <div className="missionVission smTextView mt-2 mt-sm-4">
              <h4 className="ace_span my-3">Our Vision</h4>
              <p className="pragraphStyMission">
                Our vision is to integrate artificial intelligence in medical
                and allied health education to produce world class health
                professionals of the future.
              </p>
            </div>
          </div>
          <div className="col-md-6 col-12 ">
            <div className="missionVission ourMission smTextView mt-md-4 mt-0">
              <h4 className="ace_span my-3">Our mission</h4>
              <p className="pragraphStyMission">
                Our mission is to make medical education accessible to everyone
                in the world and disrupt the current systems.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactPage;
