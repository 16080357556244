import React, { useState } from 'react';
import { useLocation, useParams, useNavigate, Link } from "react-router-dom";
import { flags } from "../../constant/flags";

const PortalPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { portalType } = useParams();
  const { countryName, portalName, countryCode } = location.state || {};
  const [inputValue, setInputValue] = useState('');
  const [words, setWords] = useState([]);

  const redirectPage = (portalType) => {
    console.log(
      `Redirecting to form with countryName: ${countryName} and portalType: ${portalType}`
    );
    navigate(`/${portalType}-Form`, {
      state: {
        countryName: countryName,
      },
    });
  };

  const [modal, setModal] = useState({ visible: false, content: {} });

  const partners = [
    {
      name: 'Equity Partners',
      heading1: 'Profile',
      heading2: 'Rights',
      heading3: 'Benefits',
      content1: 'Individuals or entities who want to invest a significant amount of capital into the company for more than 5 years.',
      content2: 'Typically shall have voting rights and a say in major business decisions.',
      content3: 'Share in the profits and growth of the company, often receive dividends, and have long-term investment benefits.'
    },
    {
      name: 'Strategic Partners',
      heading1: 'Profile',
      heading2: 'Rights',
      heading3: 'Benefits',
      content1: 'Businesses or individuals that bring strategic value beyond just capital, such as expertise, market access, or technology.',
      content2: 'May have limited voting rights depending on their level of investment and involvement.',
      content3: 'Benefit from mutual growth, shared resources, and strategic advantages that help both parties achieve their business objectives.'
    },
    {
      name: 'Silent Partners',
      heading1: 'Profile',
      heading2: 'Rights',
      heading3: 'Benefits',
      content1: 'Investors who provide capital but do not take an active role in management or operations.',
      content2: 'Generally shall not have voting rights.',
      content3: 'Receive a portion of the profits based on their investment but remain passive in day-to-day business activities.'
    },
    {
      name: 'Limited Partners',
      heading1: 'Profile',
      heading2: 'Rights',
      heading3: 'Benefits',
      content1: 'Typically part of a limited partnership structure where they invest capital but have limited liability.',
      content2: 'No involvement in management and limited voting rights.',
      content3: 'Enjoy profits and returns on their investment with limited liability exposure.'
    },
    {
      name: 'General Partners',
      heading1: 'Profile',
      heading2: 'Rights',
      heading3: 'Benefits',
      content1: 'Involved in the management and operations of the business.',
      content2: 'Full voting rights and management responsibilities.',
      content3: 'Share in profits and have a significant influence over business decisions.'
    },
    {
      name: 'Corporate Partners',
      heading1: 'Profile',
      heading2: 'Rights',
      heading3: 'Benefits',
      content1: 'Other companies that collaborate for mutual benefits.',
      content2: 'Rights vary depending on the agreement.',
      content3: 'Gain from shared resources, joint ventures, and collaborative projects.'
    }
  ];

  const seoCategories = [
    {
      heading: 'On-Page SEO',
      points: [
        'Keyword Research',
        'Meta Tags Optimization',
        'Content Optimization',
        'URL Structure Optimization',
        'Internal Linking',
        'Image Optimization',
        'Mobile Optimization',
        'User Experience Improvements'
      ]
    },
    {
      heading: 'Off-Page SEO',
      points: [
        'Link Building',
        'Social Media Marketing',
        'Influencer Outreach',
        'Guest Blogging',
        'Content Marketing',
        'Online Reputation Management'
      ]
    },
    {
      heading: 'Technical SEO',
      points: [
        'Website Speed Optimization',
        'Mobile-Friendliness',
        'XML Sitemap Creation and Submission',
        'Robots.txt Optimization',
        'SSL Implementation',
        'Structured Data Markup',
        'Canonicalization',
        '404 Error Correction'
      ]
    },
    {
      heading: 'Local SEO',
      points: [
        'Google My Business Optimization',
        'Local Citations',
        'Local Link Building',
        'Reviews and Ratings Management',
        'Localized Content Creation',
        'NAP (Name, Address, Phone Number) Consistency'
      ]
    },
    {
      heading: 'E-commerce SEO',
      points: [
        'Product Page Optimization',
        'Category Page Optimization',
        'Schema Markup for Products',
        'Customer Reviews Integration',
        'Site Structure Optimization for E-commerce'
      ]
    },
    {
      heading: 'Content SEO',
      points: [
        'Blog Post Optimization',
        'Content Creation and Strategy',
        'Content Gap Analysis',
        'Evergreen Content Creation',
        'Content Audits'
      ]
    },
    {
      heading: 'SEO Audits',
      points: [
        'Comprehensive Site Audits',
        'Technical SEO Audits',
        'Content Audits',
        'Backlink Audits',
        'Competitor Analysis'
      ]
    },
    {
      heading: 'Voice Search SEO',
      points: [
        'Optimizing for Voice Search Queries',
        'Conversational Keyword Research',
        'Implementing Structured Data for Voice Search'
      ]
    },
    {
      heading: 'International SEO',
      points: [
        'Multilingual SEO',
        'Hreflang Tag Implementation',
        'Country-specific Search Engine Optimization'
      ]
    },
    {
      heading: 'Mobile SEO',
      points: [
        'Mobile Site Speed Optimization',
        'Mobile Usability',
        'Accelerated Mobile Pages (AMP)'
      ]
    },
    {
      heading: 'Video SEO',
      points: [
        'Video Content Optimization',
        'Video Metadata Optimization',
        'Video Thumbnails and Schema Markup',
        'YouTube SEO'
      ]
    },
    {
      heading: 'App Store Optimization (ASO)',
      points: [
        'App Title and Description Optimization',
        'Keyword Optimization for Apps',
        'App Reviews and Ratings Management',
        'App Download and Engagement Strategies'
      ]
    }
  ];

  const platformFeatures = [
    {
      heading: 'User-Friendly Interface',
      points: [
        'Our platform is designed for ease of use, allowing you to set up and manage your online store with no technical expertise required.',
        'Features intuitive navigation, simple product listing processes, and a streamlined checkout experience for your customers.'
      ]
    },
    {
      heading: 'Customizable Storefronts',
      points: [
        'Personalize your store’s look and feel with a variety of customizable templates and themes.',
        'Showcase your brand’s unique identity and attract customers with visually appealing designs.'
      ]
    },
    {
      heading: 'Secure Payment Solutions',
      points: [
        'Integrated with secure payment gateways to ensure safe transactions for you and your customers.',
        'Accept multiple payment methods, including credit/debit cards, digital wallets, and bank transfers.'
      ]
    },
    {
      heading: 'Comprehensive Inventory Management',
      points: [
        'Easily manage your products, track stock levels, and receive notifications for low inventory.',
        'Utilize powerful tools to categorize products, manage pricing, and apply discounts or promotions.'
      ]
    },
    {
      heading: 'Marketing and SEO Tools',
      points: [
        'Boost your store’s visibility with built-in SEO tools to optimize product listings and increase search engine rankings.',
        'Leverage marketing features like email campaigns, social media integration, and promotional banners to attract and retain customers.'
      ]
    },
    {
      heading: 'Analytics and Reporting',
      points: [
        'Gain valuable insights into your store’s performance with detailed analytics and reporting tools.',
        'Monitor sales, track customer behavior, and make data-driven decisions to grow your business.'
      ]
    },
    {
      heading: 'Customer Support',
      points: [
        'Access dedicated support to assist you with any questions or technical issues.',
        'Benefit from resources like tutorials, FAQs, and live chat to ensure your success.'
      ]
    }
  ];

  const ecommFeatures = [
    {
      heading: 'Sign Up',
      points: [
        'Register for an account on the CSCPS E-commerce Portal.',
        'Complete the quick and easy sign-up process to access the platform.'
      ]
    },
    {
      heading: 'Set Up Your Store',
      points: [
        'Use our step-by-step setup wizard to create your store.',
        'Add your products, customize your storefront, and configure payment and shipping options.'
      ]
    },
    {
      heading: 'Launch and Sell',
      points: [
        'Launch your store and start selling to customers worldwide.',
        'Promote your store through various marketing channels and watch your business grow.'
      ]
    },
  ]

  const handleOpenModal = (partner) => {
    setModal({ visible: true, content: partner });
  };

  const handleCloseModal = () => {
    setModal({ visible: false, content: {} });
  };

  const handleChange1 = (event) => {
    const value = event.target.value;
    const wordCount = value.trim().split(' ').filter(word => word.length > 0).length;
    if (wordCount <= 5) {
      setInputValue(value);
    }
  };

  const handleSubmit1 = (event) => {
    event.preventDefault();
    const wordCount = inputValue.trim().split(' ').filter(word => word.length > 0).length;
    if (wordCount > 0 && wordCount <= 5) {
      setWords([...words, inputValue]);
      setInputValue('');
    } else {
      alert('Please enter 1 to 5 words only.');
    }
  };

  return (
    <>
      <div className="pakage_investor_back">
        <div className="d-block text-center">
          <h1 className="h11_country_cscps ms-2 me-2 m-0 p-0">{portalName}</h1>
          <h1
            className="text-white ace_span22 ms-2 me-2 m-0 p-0"
            style={{ whiteSpace: "nowrap" }}
          >
            command & staff college of <br /> physicians & surgeons of&nbsp;
            {countryName}
            {portalType === "investor-portal" && (
              <span id="investor-portal">&nbsp;LTd.</span>
            )}
          </h1>
        </div>
      </div>
      <div className="container-fluid">
        {portalType === "investor-portal" && (
          <div className="container" id="investor-portal">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-center">
                <div className="p-3 mt-5">
                  <h1 className="ace_h1_21 p-0 m-0">
                    <span className="ace_span m-0 p-0">
                      BUY YOUR PERMANENT SEAT <br />  Invest in cscps&nbsp;{countryName}
                    </span>
                  </h1>
                  <h5 className="globalizedSty my-3 text-capitalize">
                    <h4 className="ace_h4">A great investment opportunity guaranteeing highly sustainable and scalable income running into millions of dollars annually.
                      {/* with complete training to run public limited organization. Contribute directly to the cause of humanity and country’s development. */}
                    </h4>
                  </h5>
                  <p className="p2_country_cscps mt-3">
                    Ideal for business visionaries, medical professionals, educational institutions, private funds, investment banks, technology companies, foundations, and governments.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-center justify-content-center">
                <div
                  className="rounded"
                   style={{ boxShadow: "gray 0px 5px 15px" }}
                >
                  {countryCode && (
                    <img
                      src={flags[countryCode]}
                      alt={`${countryName} flag`}
                      style={{ width: "100%" }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className='row ms-2'>
              <div className='col-12'>
                <h1 className="ace_h1_21 p-0 m-0">
                  <span className="ace_span m-0 p-0">

                    Our Investors’ profile
                  </span>
                </h1>
                <p className="p2_country_cscps">
                  We have divided our investors into the following three types:
                </p>
                <ul>
                  <li className="d-flex align-items-baseline">
                    <h4 className="ace_h4">Investors:</h4>
                    <div>
                      <p className="p2_country_cscps ps-3">
                        <span className="fw-bold">
                          Small:</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;     $1,000-$50,000 for small investors
                      </p>
                      <p className="p2_country_cscps ps-3">
                        <span className="fw-bold">
                          Medium:</span>&nbsp;&nbsp;&nbsp;     $ 50,000-1,000,000 for medium sized investors, and
                      </p>
                      <p className="p2_country_cscps ps-3">
                        <span className="fw-bold">
                          Large:</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    $ 1,000,000 to $ 100,000,000 dollars and above
                      </p>
                    </div>
                  </li>
                  <li className="d-flex align-items-baseline">
                    <h4 className="ace_h4">Partners:</h4>
                    <p className="p2_country_cscps ps-3">
                      Long-term partnerships with both voting and non-voting
                      rights
                    </p>
                  </li>
                  <li className="d-flex align-items-baseline">
                    <h4 className="ace_h4">Franchises:</h4>
                    <p className="p2_country_cscps ps-3">
                      No capital injection required for franchise
                      opportunities.
                    </p>
                  </li>
                </ul>
                <p className="p2_country_cscps mt-3">
                  To apply in any of the above categories, please fill in the
                  relevant forms available in the respective sections of
                  Investment, Partners and Franchisees Portals on this website
                  for discussion, processing and approvals.
                </p>
                <button
                  onClick={() => {

                    redirectPage(portalType);
                  }}
                  className="eye_btn ps-2 pe-2 ps-lg-5 pe-lg-5 p-2 rounded-1"
                >
                  Become an Investor
                </button>
              </div>
            </div>
            <div className="row mainPage ms-2 mt-4">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h1 className="ace_h1_21 p-0 m-0 mt-4">
                  <span className="ace_span m-0 p-0">
                    Benefits of Investing with Us
                  </span>
                </h1>
                <h5 className="globalizedSty my-3 text-capitalize">
                  <h4 className="ace_h4">Prestigious Ownership:</h4>
                </h5>
                <p className="p2_country_cscps mt-3">
                  Gain shareholding in a leading brand known for excellence in
                  artificial intelligence, medicine, environmental and allied
                  health sciences, business administration, law, and more.
                </p>
                <h5 className="globalizedSty my-3 text-capitalize">
                  <h4 className="ace_h4">Solid Return on Investment:</h4>
                </h5>
                <p className="p2_country_cscps mt-3">
                  Enjoy robust returns of 15-20% per annum starting after the
                  first two years, providing a secure financial future.
                </p>
                <h5 className="globalizedSty my-3 text-capitalize">
                  <h4 className="ace_h4">Transferable Ownership:</h4>
                </h5>
                <p className="p2_country_cscps mt-3">
                  Ensure your legacy with fully transferable ownership rights to
                  your children or legally appointed heirs.
                </p>
                <h5 className="globalizedSty my-3 text-capitalize">
                  <h4 className="ace_h4">Permanent Founders Seat:</h4>
                </h5>
                <p className="p2_country_cscps mt-3">
                  Secure a permanent founders seat at our institutions,
                  guaranteeing admission for your children and future
                  generations in any field of their choice.
                </p>
                <h5 className="globalizedSty my-3 text-capitalize">
                  <h4 className="ace_h4">Deferred Payment Options:</h4>
                </h5>
                <p className="p2_country_cscps mt-3">
                  Benefit from deferred payment options on fees, with deductions
                  made from future profits, ranging from 10% to 100%.
                </p>
                <h5 className="globalizedSty my-3 text-capitalize">
                  <h4 className="ace_h4">Founders Board Membership:</h4>
                </h5>
                <p className="p2_country_cscps mt-3">
                  Join the prestigious Founders Board of any institution of your
                  choice, influencing future directions and decisions.
                </p>
                <h5 className="globalizedSty my-3 text-capitalize">
                  <h4 className="ace_h4">Elite Network Access:</h4>
                </h5>
                <p className="p2_country_cscps mt-3">
                  Become part of an exclusive and powerful business and
                  professional network, opening doors to unparalleled
                  opportunities.
                </p>
                <h5 className="globalizedSty my-3 text-capitalize">
                  <h4 className="ace_h4">
                    Long-Term Investment Opportunities:
                  </h4>
                </h5>
                <p className="p2_country_cscps mt-3">
                  Engage in long-term investments with an average span of 2-3
                  years, ensuring stable growth and returns.
                </p>
                <h5 className="globalizedSty my-3 text-capitalize">
                  <h4 className="ace_h4">Short-Term Investment Benefits:</h4>
                </h5>
                <p className="p2_country_cscps mt-3">
                  Short-term investors can take advantage of strategic
                  short-term returns on per share premiums, maximizing their
                  profits.
                </p>
              </div>
            </div>
          </div>
        )}
        {portalType === "franchise-portal" && (
          <div className="container" id="franchise-portal">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-center">
                <div className="p-3 mt-5 mb-5">
                  <h1 className="ace_h1_21 p-0 m-0">
                    <span className="ace_span m-0 p-0">
                      Welcome to the {countryName} Franchising Portal
                    </span>
                  </h1>
                  <h4 className="p2_country_cscps mt-4">
                    Enter Your Cities
                  </h4>
                  <div>
                    <form onSubmit={handleSubmit1}>
                      <input
                        type="text"
                        className="form-control w-100"
                        placeholder="Cities Name"
                        value={inputValue}
                        onChange={handleChange1}
                      />
                      <button type="submit" className='drop_country p-1 ps-4 mt-3 pe-4'>ADD</button>
                    </form>
                    <div className='d-flex w-100'>
                      {words.map((word, index) => (
                        <p className='p2_country_cscps' key={index}>{word}, &nbsp;&nbsp;&nbsp;</p>
                      ))}
                    </div>
                  </div>
                  <h5 className="globalizedSty my-4 text-capitalize">
                    <h4 className="ace_h4">You will get the following:</h4>
                  </h5>
                  <ol>
                    <li className="p2_country_cscps">
                      Guaranteed profit of 15 to 35% or more
                    </li>
                    <li className="p2_country_cscps">
                      Guaranteed 100 seats allocation per location
                    </li>
                    <li className="p2_country_cscps">World class Curriculum</li>
                    <li className="p2_country_cscps">
                      Access to International Education Portal
                    </li>
                    <li className="p2_country_cscps">
                      Access to your own admin Portal
                    </li>
                    <li className="p2_country_cscps">
                      Rights to hold CSCPS Conferences in your city
                    </li>
                    <li className="p2_country_cscps">
                      Online Classes with translation in your local language
                    </li>
                    <li className="p2_country_cscps">
                      Access to Examination Portal
                    </li>
                    <li className="p2_country_cscps">
                      Award of American Licensure Degree Programs by CSCPS
                    </li>
                    <li className="p2_country_cscps">
                      CSCPS Franchisee Passport
                    </li>
                  </ol>
                  <button
                    onClick={() => {

                      redirectPage(portalType);
                    }}
                    className="eye_btn ps-2 pe-2 ps-lg-5 pe-lg-5 p-2 rounded-1"
                  >
                    Become a Franchisee
                  </button>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-center justify-content-center">
                <div
                  className="rounded"
                   style={{ boxShadow: "gray 0px 5px 15px" }}
                >
                  {countryCode && (
                    <img
                      src={flags[countryCode]}
                      alt={`${countryName} flag`}
                      style={{ width: "100%" }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {portalType === "partner-portal" && (
          <div className="container" id="partner-portal">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-center">
                <div className="p-3 mt-5 mb-5">
                  <h1 className="ace_h1_21 p-0 m-0">
                    <span className="ace_span m-0 p-0">
                      Become our partners in cscps&nbsp;{countryName}
                    </span>
                  </h1>
                  <h5 className="globalizedSty my-3 text-capitalize">
                    <h4 className="ace_h4">We have categorized our partners in the following 6 categories:

                    </h4>
                  </h5>
                  <p className="p2_country_cscps mt-3 fw-bold" style={{ color: "red" }}>
                    Please click on the headings below:
                  </p>
                  <div>
                    <ol>
                      {partners.map((partner, index) => (
                        <li
                          key={index}
                          className="ace_h4 w-50"
                          onClick={() => handleOpenModal(partner)}
                          style={{ cursor: "pointer", width: "180px" }}
                        >
                          {partner.name}
                        </li>
                      ))}
                    </ol>
                    {modal.visible && (
                      <div className="modal show">
                        <div className="modal-content modal_content">
                          <h5 className="fw-bold text-dark">{modal.content.heading1}</h5>
                          <p>{modal.content.content1}</p>
                          <h5 className="fw-bold text-dark">{modal.content.heading2}</h5>
                          <p>{modal.content.content2}</p>
                          <h5 className="fw-bold text-dark">{modal.content.heading3}</h5>
                          <p>{modal.content.content3}</p>
                          <span className="close" onClick={handleCloseModal}>&times;</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <p className="p2_country_cscps mt-3">
                    To apply as a partner, please fill out the form below. <br /> For queries, email to investments@cscps.org for further processing.
                  </p>
                  <div className='mobile_button_center'>
                    <button
                      onClick={() => {

                        redirectPage(portalType);
                      }}
                      className="eye_btn ps-2 pe-2 ps-lg-5 pe-lg-5 p-2 rounded-1"
                    >
                      Become a Partner
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-center justify-content-center">
                <div
                  className="rounded"
                   style={{ boxShadow: "gray 0px 5px 15px" }}
                >
                  {countryCode && (
                    <img
                      src={flags[countryCode]}
                      alt={`${countryName} flag`}
                      style={{ width: "100%" }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="row mainPage mt-4">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h1 className="ace_h1_21 p-0 m-0">
                  <span className="ace_span m-0 p-0">
                    Benefits of Investing with Us
                  </span>
                </h1>
                <h5 className="globalizedSty my-3 text-capitalize">
                  <h4 className="ace_h4">Prestigious Ownership:</h4>
                </h5>
                <p className="p2_country_cscps mt-3">
                  Gain shareholding in a leading brand known for excellence in
                  artificial intelligence, medicine, environmental and allied
                  health sciences, business administration, law, and more.
                </p>
                <h5 className="globalizedSty my-3 text-capitalize">
                  <h4 className="ace_h4">Solid Return on Investment:</h4>
                </h5>
                <p className="p2_country_cscps mt-3">
                  Enjoy robust returns of 15-20% per annum starting after the
                  first two years, providing a secure financial future.
                </p>
                <h5 className="globalizedSty my-3 text-capitalize">
                  <h4 className="ace_h4">Transferable Ownership:</h4>
                </h5>
                <p className="p2_country_cscps mt-3">
                  Ensure your legacy with fully transferable ownership rights to
                  your children or legally appointed heirs.
                </p>
                <h5 className="globalizedSty my-3 text-capitalize">
                  <h4 className="ace_h4">Permanent Founders Seat:</h4>
                </h5>
                <p className="p2_country_cscps mt-3">
                  Secure a permanent founders seat at our institutions,
                  guaranteeing admission for your children and future
                  generations in any field of their choice.
                </p>
                <h5 className="globalizedSty my-3 text-capitalize">
                  <h4 className="ace_h4">Deferred Payment Options:</h4>
                </h5>
                <p className="p2_country_cscps mt-3">
                  Benefit from deferred payment options on fees, with deductions
                  made from future profits, ranging from 10% to 100%.
                </p>
                <h5 className="globalizedSty my-3 text-capitalize">
                  <h4 className="ace_h4">Founders Board Membership:</h4>
                </h5>
                <p className="p2_country_cscps mt-3">
                  Join the prestigious Founders Board of any institution of your
                  choice, influencing future directions and decisions.
                </p>
                <h5 className="globalizedSty my-3 text-capitalize">
                  <h4 className="ace_h4">Elite Network Access:</h4>
                </h5>
                <p className="p2_country_cscps mt-3">
                  Become part of an exclusive and powerful business and
                  professional network, opening doors to unparalleled
                  opportunities.
                </p>

                <h5 className="globalizedSty my-3 text-capitalize">
                  <h4 className="ace_h4">
                    Long-Term Investment Opportunities:
                  </h4>
                </h5>
                <p className="p2_country_cscps mt-3">
                  Engage in long-term investments with an average span of 2-3
                  years, ensuring stable growth and returns.
                </p>

                <h5 className="globalizedSty my-3 text-capitalize">
                  <h4 className="ace_h4">Short-Term Investment Benefits:</h4>
                </h5>
                <p className="p2_country_cscps mt-3">
                  Short-term investors can take advantage of strategic
                  short-term returns on per share premiums, maximizing their
                  profits.
                </p>
              </div>
            </div>
          </div>
        )}
        {portalType === "education-portal" && (
          <div className="container" id="education-portal">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 d-flex align-items-center">
                <div className="p-3 mt-5 mb-5">
                  <h1 className="ace_h1_21 p-0 m-0">
                    <span className="ace_span m-0 p-0">
                      Welcome to {countryName} Eductaional Portal
                    </span>
                  </h1>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <p className="p2_country_cscps mt-3">
                        We are disrupting global medical education with our innovative Ai augmented education system consisting of a multi-tiered education program. Better than all competitors combined.
                      </p>
                      <h5 className="globalizedSty my-3 text-capitalize">
                        <h4 className="ace_h4">Benefits and Advantages:
                        </h4>
                      </h5>
                      <ol>
                        <li>An accredited regulatory authority factoring in each country’s regulatory framework</li>
                        <li>Internationally recognized degree programs in multiple disciplines</li>
                        <li>Fast, expanded learning made easy</li>
                        <li>Each country would get a customized curriculum to ensure regulatory compliance</li>
                        <li>All educational institutions would undergo international accreditation</li>
                        <li>Dedicated Board of Examination</li>
                        <li>Very powerful, legal apparatus</li>
                        <li>Highest standards of futuristic medicine program</li>
                        <li>Independent internships program selection</li>
                        <li>Online, offline and combination models</li>
                        <li>Global campuses, worldwide</li>
                        <li>Easiest intra-system transfer between campuses</li>
                        <li>Minimum cognitive load with maximum skillset focus</li>
                        <li>No age limit for admittance to the program</li>
                        <li>International examinations integration</li>
                        <li>Copyright protected curriculum and academic dynamics</li>
                        <li>Partnerships with multiple educational institutions</li>
                        <li>Vigorous teachers training programs</li>
                        <li>All students stand a business and job guarantee</li>
                        <li>Not just doctors, but scientists and technologist training programs</li>
                        <li>Direct program admission from grade VIII onwards</li>
                        <li>International alumni</li>
                        <li>Already 50,000 plus enrollments in process</li>
                        <li>Highly profitable education business due to AI based controlled expenditures</li>
                        <li>Franchisees training programs on education</li>
                        <li>… this list goes on</li>
                      </ol>
                      <button
                        onClick={() => {

                          redirectPage(portalType);
                        }}
                        className="eye_btn mt-4 ps-2 pe-2 ps-lg-5 pe-lg-5 p-2 rounded-1"
                      >
                        Fill The Form
                      </button>
                      <h1 className="ace_h1_21 mt-4 p-0 m-0">
                        <span className="ace_span m-0 p-0">
                          We are currently processing previous applications
                        </span>
                      </h1>
                      <p className="p2_country_cscps mt-3">
                        For queries, please send us an email at <a href="mailto:ed@cscps.org">ed@cscps.org</a>.<br />
                        New dates will be announced concurrently.
                      </p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 d-flex justify-content-center">
                      <div>
                        <div
                          className="rounded"
                           style={{ boxShadow: "gray 0px 5px 15px" }}
                        >
                          {countryCode && (
                            <img
                              src={flags[countryCode]}
                              alt={`${countryName} flag`}
                              style={{ width: "100%" }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {portalType === "seo-portal" && (
          <div className="container" id="seo-portal">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-center">
                <div className="p-3 mt-5 mb-5">
                  <h1 className="ace_h1_21 p-0 m-0">
                    <span className="ace_span m-0 p-0">
                      Welcome to SEO PORTAL OF&nbsp; {countryName}
                    </span>
                  </h1>
                  <p className="p2_country_cscps mt-3">
                    We provide multiple SEO services to provide comprehensive solutions tailored to meet the specific needs of different clients, ensuring improved visibility and performance in search engine results. We have tried to provide a one-stop-shop solution for all your needs with the following services.
                  </p>
                  <button
                    onClick={() => {

                      redirectPage(portalType);
                    }}
                    className="eye_btn mt-5 ps-2 pe-2 ps-lg-5 pe-lg-5 p-2 rounded-1"
                  >
                    Fill The Form
                  </button>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-center justify-content-center">
                <div
                  className="rounded"
                   style={{ boxShadow: "gray 0px 5px 15px" }}
                >
                  {countryCode && (
                    <img
                      src={flags[countryCode]}
                      alt={`${countryName} flag`}
                      style={{ width: "100%" }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              {seoCategories.map((category, index) => (
                <div className="col-lg-4 col-md-4 col-sm-12" key={index}>
                  <h5 className="globalizedSty my-3 text-capitalize">
                    <h4 className="ace_h4">{category.heading}</h4>
                  </h5>
                  <ol>
                    {category.points.map((point, pointIndex) => (
                      <li className="p2_country_cscps" key={pointIndex}>{point}</li>
                    ))}
                  </ol>
                </div>
              ))}
            </div>
          </div>
        )}
        {portalType === "advertise-portal" && (
          <div className="container" id="advertise-portal">
            <div className="row">
              <div className="col-lg-7 col-md-7 col-sm-12 d-flex align-items-center">
                <div className="p-3 mt-5 mb-5">
                  <h1 className="ace_h1_21 p-0 m-0">
                    <span className="ace_span m-0 p-0">
                      Welcome to the {countryName} Advertising Portal
                    </span>
                  </h1>
                  <h4 className="mt-4">
                    You can now directly place your ad or can become our ad agency in your country and make a substantial 30% earning on every ad that you book.

                  </h4>
                  <h5 className="globalizedSty my-3 text-capitalize">
                    <h4 className="ace_h4">Benefits and Advantages:                      </h4>
                  </h5>
                  <ol>
                    <li>We have restricted and maintain ad agencies according to the performance based on meeting weekly and monthly targets.</li>
                    <li>If you are a client who wishes to advertise directly on this portal, you can do so till such time that we have appointed an ad agency.</li>
                    <li>Ads will only appear on your country’s assigned pages only.</li>
                    <li>For multiple countries, please choose from the packages shown below on the page.</li>
                    <li>All ads are placed in the order they are received. The oldest remain on the top. To keep your ad on the top, simply opt for a premium placement and maintain the top slot on monthly basis.</li>
                    <li>Ads can be only posted according to the sizes provided.</li>
                    <li>We provide 24/7 support. If you have any questions or want to place your ads, please contact our team and send us an email at <a href="mailto:advertise@cscps.org">advertise@cscps.org</a>.</li>
                  </ol>
                  <button
                    onClick={() => {

                      redirectPage(portalType);
                    }}
                    className="eye_btn ps-2 pe-2 ps-lg-5 pe-lg-5 p-2 rounded-1"
                  >
                    Advertise Now
                  </button>
                </div>
              </div>
              <div className="col-lg-5 col-md-5 col-sm-12 d-flex align-items-center justify-content-center">
                <div
                  className="rounded"
                   style={{ boxShadow: "gray 0px 5px 15px" }}
                >
                  {countryCode && (
                    <img
                      src={flags[countryCode]}
                      alt={`${countryName} flag`}
                      style={{ width: "100%" }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="row mainPage">
              <div className="col-lg-12 col-md-12 col-sm-12 pt-3 pt-md-0">
                <div className="row  pt-5 smScreen sm-py-20">
                  <div className="col-lg-5 col-md-5 col-sm-12">
                    <h1 className="ace_h1_21 p-0 m-0">
                      <span className="ace_span m-0 p-0">Packages</span>
                    </h1>
                    <h5 className="globalizedSty my-3 text-capitalize">
                      <h4 className="ace_h4">Choose your package now!</h4>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="border border-2 rounded-3 p-3 mt-4 mb-5 ">
                  <h4 className="ace_h46 mt-3">Starter:</h4>
                  <h1 className="ace_h1_2 p-0 m-0 text-center mt-4">
                    <span className="ace_span m-0 p-0">For 50 countries
                    </span>
                  </h1>
                  <h1 className="ace_h1_21 p-0 m-0 text-center">
                    <span className="ace_span m-0 p-0">$ 50 a month</span>
                  </h1>
                  <h1 className="ace_h1_21 p-0 m-0 text-center mt-4">
                    <span className="ace_span m-0 p-0">For Premium Placing,
                    </span>
                  </h1>
                  <h1 className="ace_h1_21 p-0 m-0 text-center">
                    <span className="ace_span m-0 p-0">Please add $100 extra
                    </span>
                  </h1>
                  <div className="mt-4 text-center">
                    <Link
                      className="listiFirst text-decoration-none"
                      to="/payment-gateway"
                    >
                      <button className="eye_btn rounded p-2 ps-3 pe-3">
                        BUY NOW
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="border border-2 rounded-3 p-3 mt-4 mb-5 ">
                  <h4 className="ace_h46 mt-3">Economy:</h4>
                  <h1 className="ace_h1_2 p-0 m-0 text-center mt-4">
                    <span className="ace_span m-0 p-0">For 80 countries
                    </span>
                  </h1>
                  <h1 className="ace_h1_21 p-0 m-0 text-center">
                    <span className="ace_span m-0 p-0">$ 80 a month</span>
                  </h1>
                  <h1 className="ace_h1_21 p-0 m-0 text-center mt-4">
                    <span className="ace_span m-0 p-0">For Premium Placing,
                    </span>
                  </h1>
                  <h1 className="ace_h1_21 p-0 m-0 text-center">
                    <span className="ace_span m-0 p-0">Please add $100 extra
                    </span>
                  </h1>
                  <div className="mt-4 text-center">
                    <Link
                      className="listiFirst text-decoration-none"
                      to="/payment-gateway"
                    >
                      <button className="eye_btn rounded p-2 ps-3 pe-3">
                        BUY NOW
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="border border-2 rounded-3 p-3 mt-4 mb-5 ">
                  <h4 className="ace_h46 mt-3">Super Economy:</h4>
                  <h1 className="ace_h1_2 p-0 m-0 text-center mt-4">
                    <span className="ace_span m-0 p-0">For All countries
                    </span>
                  </h1>
                  <h1 className="ace_h1_21 p-0 m-0 text-center">
                    <span className="ace_span m-0 p-0">$ 150 a month</span>
                  </h1>
                  <h1 className="ace_h1_21 p-0 m-0 text-center mt-4">
                    <span className="ace_span m-0 p-0">For Premium Placing,
                    </span>
                  </h1>
                  <h1 className="ace_h1_21 p-0 m-0 text-center">
                    <span className="ace_span m-0 p-0">Please add $100 extra
                    </span>
                  </h1>
                  <div className="mt-4 text-center">
                    <Link
                      className="listiFirst text-decoration-none"
                      to="/payment-gateway"
                    >
                      <button className="eye_btn rounded p-2 ps-3 pe-3">
                        BUY NOW
                      </button>
                    </Link>
                  </div>
                </div>
              </div>

            </div>
          </div>
        )}
        {portalType === "news-portal" && (
          <div className="container" id="news-portal">
            <div className="row">
              <div className="col-lg-7 col-md-7 col-sm-12 d-flex align-items-center">
                <div className="p-3 mt-5 mb-5">
                  <h1 className="ace_h1_21 p-0 m-0">
                    <span className="ace_span m-0 p-0">
                      Welcome to the Medical News Portal
                    </span>
                  </h1>
                  <h4 className=" mt-4">
                    You can now start your own journal, magazine or publish news
                    by simply clicking on the button. We provide a complete
                    support and back up while you are thinking.
                  </h4>
                  <h5 className="globalizedSty my-3 text-capitalize">
                    <h4 className="ace_h4">Benefits</h4>
                  </h5>
                  <ol>
                    <li className="p2_country_cscps">
                      It’s a readymade, plug-in system
                    </li>
                    <li className="p2_country_cscps">
                      Let the world know of your potential
                    </li>
                    <li className="p2_country_cscps">
                      Learn to use AI in helping you out
                    </li>
                    <li className="p2_country_cscps">
                      We provide marketing support for your magazine
                    </li>
                    <li className="p2_country_cscps">
                      We also provide RSS feeds for news publishing
                    </li>
                    <li className="p2_country_cscps">
                      We will teach you how to turn it into a money maker
                    </li>
                    <li className="p2_country_cscps">Best for students</li>
                    <li className="p2_country_cscps">
                      Advertise your product globally
                    </li>
                    <li className="p2_country_cscps">
                      Earn Ad money from your magazine
                    </li>
                    <li className="p2_country_cscps">
                      We can even sort your published copy
                    </li>
                  </ol>

                  <button
                    onClick={() => {

                      redirectPage(portalType);
                    }}
                    className="eye_btn ps-2 pe-2 ps-lg-5 pe-lg-5 p-2 rounded-1"
                  >
                    Fill The Form
                  </button>
                </div>
              </div>
              <div className="col-lg-5 col-md-5 col-sm-12 d-flex align-items-center justify-content-center">
                <div
                  className="rounded"
                   style={{ boxShadow: "gray 0px 5px 15px" }}
                >
                  {countryCode && (
                    <img
                      src={flags[countryCode]}
                      alt={`${countryName} flag`}
                      style={{ width: "100%" }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {portalType === "ecommerce-portal" && (
          <div className="container" id="ecommerce-portal">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-center">
                <div className="p-3 mt-5 mb-5">
                  <h1 className="ace_h1_21 p-0 m-0">
                    <span className="ace_span m-0 p-0">
                      Welcome to the CSCPS&nbsp;{countryName} Ecommerce Portal
                    </span>
                  </h1>
                  <h5 className="globalizedSty my-3 text-capitalize">
                    <h4 className="ace_h4">Launch Your Online Store with CSCPS E-commerce Portal
                    </h4>
                  </h5>
                  <p className="p2_country_cscps mt-3">
                    At the Command & Staff College of Physicians & Surgeons (CSCPS), we are excited to offer a comprehensive e-commerce platform that empowers you to launch your own online store seamlessly. Whether you are a seasoned business owner or an aspiring entrepreneur, our portal provides the tools and resources you need to succeed in the digital marketplace.
                  </p>
                  <button
                    onClick={() => {

                      redirectPage(portalType);
                    }}
                    className="eye_btn mt-3 ps-2 pe-2 ps-lg-5 pe-lg-5 p-2 rounded-1"
                  >
                    Fill The Form
                  </button>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-center justify-content-center">
                <div
                  className="rounded"
                   style={{ boxShadow: "gray 0px 5px 15px" }}
                >
                  {countryCode && (
                    <img
                      src={flags[countryCode]}
                      alt={`${countryName} flag`}
                      style={{ width: "100%" }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <h1 className="ace_h1_21 ms-4 mb-3 p-0 m-0">
                <span className="ace_span m-0 p-0">
                  Why Choose CSCPS E-commerce Portal?
                </span>
              </h1>
              {platformFeatures.map((feature, index) => (
                <div className="col-lg-4 col-md-4 col-sm-12" key={index}>
                  <h5 className="globalizedSty my-3 text-capitalize">
                    <h4 className="ace_h4">{feature.heading}</h4>
                  </h5>
                  <ol>
                    {feature.points.map((point, pointIndex) => (
                      <li className="p2_country_cscps" key={pointIndex}>{point}</li>
                    ))}
                  </ol>
                </div>
              ))}
            </div>
            <div className="row mt-3">
              <h1 className="ace_h1_21 ms-4 mb-3 p-0 m-0">
                <span className="ace_span m-0 p-0">
                  How to get Started
                </span>
              </h1>
              {ecommFeatures.map((feature, index) => (
                <div className="col-lg-4 col-md-4 col-sm-12" key={index}>
                  <h5 className="globalizedSty my-3 text-capitalize">
                    <h4 className="ace_h4">{feature.heading}</h4>
                  </h5>
                  <ol>
                    {feature.points.map((point, pointIndex) => (
                      <li className="p2_country_cscps" key={pointIndex}>{point}</li>
                    ))}
                  </ol>
                </div>
              ))}
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <h5 className="globalizedSty my-3 text-capitalize">
                  <h4 className="ace_h4">Join Our Community
                  </h4>
                </h5>
                <p className="p2_country_cscps mt-3">
                  By joining the CSCPS E-commerce Portal, you become part of a vibrant community of sellers and entrepreneurs. Take advantage of networking opportunities, collaborative projects, and shared resources to enhance your business journey.

                </p>
                <p className="p2_country_cscps mt-3">
                  Start your e-commerce venture with CSCPS today and turn your passion into profit. For more information or to get started, visit our website or contact our support team.

                </p>
                <h5 className="globalizedSty my-3 text-capitalize">
                  <h4 className="ace_h4">Email: ecommerce@cscps.ae
                  </h4>
                </h5>
              </div>
            </div>
          </div>
        )}
        {portalType === "blogs-portal" && (
          <div className="container" id="blogs-portal">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-center">
                <div className="p-3 mt-5 mb-5">
                  <h1 className="ace_h1_21 p-0 m-0">
                    <span className="ace_span m-0 p-0">
                      Welcome to the {countryName} Blogging
                      Portal
                    </span>
                  </h1>
                  <p className="p2_country_cscps mt-3">
                    You can now create your legacy by contributing on a
                    dedicated page assigned to you on this platform. You
                    can choose form a wide variety of topics or simply
                    create your own topic if we have missed.
                  </p>
                  <h5 className="globalizedSty my-3 text-capitalize">
                    <h4 className="ace_h4">Benefits and Advantages:
                    </h4>
                  </h5>
                  <ol>
                    <li>Worldwide recognition in the medical and allied fields</li>
                    <li>Give your opinion a voice</li>
                    <li>Whatever you choose will appear on your country’s page with your name indexed on a bloggers page from that country</li>
                    <li>We offer blogs marketing services</li>
                    <li>Posting on your country’s page is free</li>
                    <li>If you want your logs to appear worldwide, use our premium logging service for $10.00, and it will show on every country’s page</li>
                    <li>Blogs can also be placed on the advertising portal to talk to your clients or position yourself in the market</li>
                    <li>All blogs are placed in the order they are received. The oldest remain on the top. To keep your ad on the top, simply opt for a premium placement and maintain the top slot on monthly basis.</li>
                    <li>For queries, please send us an email at blogging@cscps.org</li>
                  </ol>
                  <Link to="/blogs-home-page" className='text-decoration-none'>
                  <button
                    className="eye_btn mt-3 ps-2 pe-2 ps-lg-5 pe-lg-5 p-2 rounded-1"
                    >
                    All Blogs
                  </button>
                    </Link>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-center justify-content-center">
                <div
                  className="rounded"
                   style={{ boxShadow: "gray 0px 5px 15px" }}
                >
                  {countryCode && (
                    <img
                      src={flags[countryCode]}
                      alt={`${countryName} flag`}
                      style={{ width: "100%" }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="border border-2 rounded-3 p-3 mb-5">
                  <h4 className="ace_h46 mt-3">Starter:</h4>
                  <h1 className="ace_h1_2 p-0 m-0 text-center mt-4">
                    <span className="ace_span m-0 p-0">For 50 countries
                    </span>
                  </h1>
                  <h1 className="ace_h1_21 p-0 m-0 text-center">
                    <span className="ace_span m-0 p-0">$ 50 a month</span>
                  </h1>
                  <h1 className="ace_h1_21 p-0 m-0 text-center mt-4">
                    <span className="ace_span m-0 p-0">For Premium Placing,
                    </span>
                  </h1>
                  <h1 className="ace_h1_21 p-0 m-0 text-center">
                    <span className="ace_span m-0 p-0">Please add $100 extra
                    </span>
                  </h1>
                  <div className="mt-4 text-center">
                    <Link
                      className="listiFirst text-decoration-none"
                      to="/payment-gateway"
                    >
                      <button className="eye_btn rounded p-2 ps-3 pe-3">
                        BUY NOW
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="border border-2 rounded-3 p-3 mb-5">
                  <h4 className="ace_h46 mt-3">Economy:</h4>
                  <h1 className="ace_h1_2 p-0 m-0 text-center mt-4">
                    <span className="ace_span m-0 p-0">For 80 countries
                    </span>
                  </h1>
                  <h1 className="ace_h1_21 p-0 m-0 text-center">
                    <span className="ace_span m-0 p-0">$ 80 a month</span>
                  </h1>
                  <h1 className="ace_h1_21 p-0 m-0 text-center mt-4">
                    <span className="ace_span m-0 p-0">For Premium Placing,
                    </span>
                  </h1>
                  <h1 className="ace_h1_21 p-0 m-0 text-center">
                    <span className="ace_span m-0 p-0">Please add $100 extra
                    </span>
                  </h1>
                  <div className="mt-4 text-center">
                    <Link
                      className="listiFirst text-decoration-none"
                      to="/payment-gateway"
                    >
                      <button className="eye_btn rounded p-2 ps-3 pe-3">
                        BUY NOW
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="border border-2 rounded-3 p-3 mb-5">
                  <h4 className="ace_h46 mt-3">Super Economy:</h4>
                  <h1 className="ace_h1_2 p-0 m-0 text-center mt-4">
                    <span className="ace_span m-0 p-0">For All countries
                    </span>
                  </h1>
                  <h1 className="ace_h1_21 p-0 m-0 text-center">
                    <span className="ace_span m-0 p-0">$ 150 a month</span>
                  </h1>
                  <h1 className="ace_h1_21 p-0 m-0 text-center mt-4">
                    <span className="ace_span m-0 p-0">For Premium Placing,
                    </span>
                  </h1>
                  <h1 className="ace_h1_21 p-0 m-0 text-center">
                    <span className="ace_span m-0 p-0">Please add $100 extra
                    </span>
                  </h1>
                  <div className="mt-4 text-center">
                    <Link
                      className="listiFirst text-decoration-none"
                      to="/payment-gateway"
                    >
                      <button className="eye_btn rounded p-2 ps-3 pe-3">
                        BUY NOW
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {portalType !== "investor-portal" &&
          portalType !== "franchise-portal" &&
          portalType !== "partner-portal" &&
          portalType !== "advertise-portal" &&
          portalType !== "blogs-portal" &&
          portalType !== "news-portal" &&
          portalType !== "ecommerce-portal" &&
          portalType !== "seo-portal" &&
          portalType !== "education-portal" && (
            <div id="another">
              <h1 className="pt-5 ace_h1_21 p-0 m-0">Still Working</h1>
            </div>
          )}
      </div>
    </>
  );
};

export default PortalPage;
