import axios from "axios";

const BASEURL = "https://api.cscps.org";
// const BASEURL = "http://localhost:3400";

export const postRequest = async(endPoint,data)=>{
  return await axios.post(`${BASEURL}${endPoint}`,data);
}

export const getRequest = async (endPoint) => {
  return await axios.get(`${BASEURL}${endPoint}`);
};