import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from '../assets/images/Ammad.jpeg';
import { ChevronDown } from 'lucide-react';
import { Link } from "react-router-dom";

const DashboardNavbar = () => {
    return (
        <nav className="navbar navbar-expand-lg" style={{ backgroundColor: "#f5f5f5" }}>
            <p className='ace_h1_102 ps-3 m-0'>
                command & staff college of physicians & surgeons
            </p>
            <div className="ms-auto d-flex gap-3 align-items-center">
                <div>
                <p className='ace_h1_102 ps-3 m-0'>
                Muhammad Usman
            </p>
                </div>
                <div className="dropdown me-3">
                    <button
                        className="border p-1 ps-2 pe-2 rounded-3 d-flex align-items-center"
                        type="button"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{ backgroundColor: "white" }}
                    >
                        <div>
                            <img src={Logo} alt="Logo" style={{
                                borderRadius: "50%",
                                width: "40px",
                                height: "40px",
                                objectFit: "cover"
                            }} /></div>
                        <div>
                            <ChevronDown color="black" />
                        </div>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                        <li><Link className="dropdown-item" to="/">Profile Setting</Link></li>
                        <li><Link className="dropdown-item" to="/">Log Out</Link></li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default DashboardNavbar;
