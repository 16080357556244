import React, { useState, useEffect } from "react";
import { ArrowBigRightDash } from "lucide-react";
import { MDBCarousel, MDBCarouselItem } from "mdb-react-ui-kit";
import Swal from "sweetalert2";
import emailjs from "emailjs-com";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import Logo from "../assets/images/logo.png";
import ProfileAvatar from "../assets/images/girl_avatar.jpg";
import "./LandingPage.css";
import { Link } from "react-router-dom";

const FranchisingLandingPage = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    nationality: "",
    profession: "",
    email: "",
    phoneNumber: "",
    cityOfResidence: "",
    comments: "",
    emailError: false,
    emailMessage: "",
  });
  const [phoneError, setPhoneError] = useState(false);

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const validateEmail = (email) => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });

    if (e.target.id === "email") {
      if (!validateEmail(e.target.value)) {
        setFormData((prevData) => ({
          ...prevData,
          emailError: true,
          emailMessage: "Invalid email",
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          emailError: false,
          emailMessage: "",
        }));
      }
    }
  };

  const validatePhoneNumber = (input_str) => {
    const re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
    return re.test(input_str);
  };

  const handlePhoneChange = (value) => {
    setFormData({ ...formData, phoneNumber: value });
    if (!validatePhoneNumber(value)) {
      setPhoneError(true);
    } else {
      setPhoneError(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Submitting form...");

    if (phoneError || !validatePhoneNumber(formData.phoneNumber)) {
      setPhoneError(true);
      return;
    }
    if (!validateEmail(formData.email)) {
      setFormData((prevData) => ({
        ...prevData,
        emailError: true,
        emailMessage: "Invalid email",
      }));
      return;
    }

    // Send email using EmailJS
    emailjs
      .send(
        "service_z8cr3lf",
        "template_b6l6e0k",
        {
          fullName: formData.fullName,
          nationality: formData.nationality,
          profession: formData.profession,
          email: formData.email,
          phoneNumber: formData.phoneNumber,
          cityOfResidence: formData.cityOfResidence,
          comments: formData.comments,
          to_name: formData.fullName,
          to_email: formData.email,
        },
        "IpakiaUw3rbKMdmV6"
      )
      .then(
        (result) => {
          console.log("Email sent successfully: ", result.text);
          Swal.fire({
            icon: "success",
            title: "Successful",
            text: "Our team will shortly get in touch with you for further discussion. Please Check your Email and see the investor packages",
          });

          // Optional: Reset form fields after successful submission
          setFormData({
            fullName: "",
            nationality: "",
            profession: "",
            email: "",
            phoneNumber: "",
            cityOfResidence: "",
            comments: "",
            emailError: false,
            emailMessage: "",
          });
        },
        (error) => {
          console.error("EmailJS Error: ", error.text);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to submit form. Please try again.",
          });
        }
      );
  };

  return (
    <div>
      {/* 1. Hero Section with Background Image */}
      <div className="container">
        <div className="row g-0">
          <div className="col-12">
            <div className="text-center mt-5 mt-lg-5 pt-3 pt-lg-5">
              <img src={Logo} className="logo_img" alt="logo" />
              <p className="first_section_p text-dark pt-3 pt-lg-5">
                Welcome to the
              </p>
              <p className="first_section_p_1 text-white rounded-3 pt-4 pb-4 m-0">
                Command & Staff College of Physicians & Surgeons
              </p>
              <p className="first_section_p_2 text-dark mt-5">
                Anyone, anywhere can now become a medical doctor through our
                world class American Program,
              </p>
              <h5 className="first_section_h5 text-uppercase text-dark fw-bold">
                THE AI ENVIRONMENT & MEDICAL SCIENTIST TRAINING PROGRAM
              </h5>
            </div>
          </div>
        </div>
      </div>

      {/* 3. Custom Video */}
      <section className="video-section bg-light mt-lg-5 p-0">
        <div className="container-fluid p-0 m-0 text-center pt-3 pb-3 pb-lg-5">
          <h2 className="section_section_h2 text-capitalize text-dark mt-3">
            Watch our full video
          </h2>
          <div className="row mt-3 g-0">
            <div className="col-12">
              <div
                style={{
                  position: "relative",
                  paddingBottom: "56.25%",
                  height: 0,
                  overflow: "hidden",
                  maxWidth: "100%",
                }}
              >
                <iframe
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  src="https://www.youtube.com/embed/puH-7nhoITE?autoplay=1&controls=0&showinfo=0&rel=0&modestbranding=1&loop=1&mute=0"
                  title="Revolutionizing Medical Education with CSCPS | Become a Leader in Medicine, AI and More"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="row g-0">
          <div className="col-12">
            <div className="text-center mt-4">
              <p className="first_section_p_1 text-white rounded-3 pt-4 pb-4 m-0">
                Join the Future of Education
                <br className="d-none d-sm-block" /> Become a CSCPS Franchisee
              </p>
              <p className="first_section_p_2 text-dark mt-4">
                "Be part of the revolution in medical, environmental, and
                AI-integrated education. Invest in CSCPS Ph.D. labs and help
                shape the leaders of tomorrow while becoming a
                multi-millionaire."
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container py-5">
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
          <div className="col">
            <div className="card h-100 shadow-sm hover-effect position-relative">
              <div className="ribbon red">
                <span>Benefit</span>
              </div>
              <div className="card-body">
                <h5 className="section_third_card_title">
                  High Return on Investment
                </h5>
                <p className="card-text">
                  Be part of an innovative education model that integrates AI,
                  healthcare, and business—designed to create
                  multi-million-dollar opportunities.
                </p>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card h-100 shadow-sm hover-effect position-relative">
              <div className="ribbon green">
                <span>Benefit</span>
              </div>
              <div className="card-body">
                <h5 className="section_third_card_title">
                  Exclusive Ph.D. Labs
                </h5>
                <p className="card-text">
                  Own and operate advanced Ph.D. labs in medicine, environment,
                  and AI research, contributing to breakthrough innovations.
                </p>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card h-100 shadow-sm hover-effect position-relative">
              <div className="ribbon blue">
                <span>Benefit</span>
              </div>
              <div className="card-body">
                <h5 className="section_third_card_title">
                  Proven Business Model
                </h5>
                <p className="card-text">
                  Join a global education movement with a structured franchise
                  system, backed by Public Companies Holdings Limited.
                </p>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card h-100 shadow-sm hover-effect position-relative">
              <div className="ribbon orange">
                <span>Benefit</span>
              </div>
              <div className="card-body">
                <h5 className="section_third_card_title">
                  Cutting-Edge Education
                </h5>
                <p className="card-text">
                  Offer a revolutionary multi-degree program in medicine,
                  environment, and AI—designed to prepare the next generation of
                  leaders.
                </p>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card h-100 shadow-sm hover-effect position-relative">
              <div className="ribbon pink">
                <span>Benefit</span>
              </div>
              <div className="card-body">
                <h5 className="section_third_card_title">Ongoing Support</h5>
                <p className="card-text">
                  As a franchisee, you’ll receive comprehensive support,
                  training, and resources to ensure your success.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row g-0">
          <h2 className="section_section_h2 text-capitalize text-dark mt-3">
            Why Invest in CSCPS:
          </h2>
          <div className="col-12">
            <ol className="m-0 ps-4">
              <li className="section_fourth_li">
                <span className="fw-bold text-capitalize">
                  Global Demand for Innovation:
                </span>
                The healthcare and education sectors are evolving rapidly, and
                CSCPS is at the forefront of this change.
              </li>
              <li className="section_fourth_li">
                <span className="fw-bold text-capitalize">
                  AI-Driven Education:
                </span>
                CSCPS integrates artificial intelligence into its curriculum,
                attracting top talent and producing future-ready graduates.
              </li>
              <li className="section_fourth_li">
                <span className="fw-bold text-capitalize">
                  Unique Revenue Opportunities:
                </span>
                From Ph.D. labs to degree programs, CSCPS provides multiple
                income streams and long-term growth potential.
              </li>
              <li className="section_fourth_li">
                <span className="fw-bold text-capitalize">
                  Join a Global Movement:
                </span>
                Be part of an educational revolution that’s “of the people, by
                the people, for the people,” and help shape the future of global
                healthcare.
              </li>
            </ol>
            <Link className="text-decoration-none" to="/">
              <button className="button-33 mt-4">Call to Action</button>
            </Link>
            <p className="first_section_p_2 text-dark mt-5">
              <ArrowBigRightDash /> Start Your Journey as a CSCPS Franchisee
              Today—Request a Consultation Now
            </p>
          </div>
        </div>
      </div>

      <div className="container p-3" style={{ maxWidth: "1200px" }}>
        <form
          onSubmit={handleSubmit}
          className="w-100 p-0 p-lg-4"
          style={{
            boxShadow: "0 !Important",
          }}
        >
          <div className="row g-4">
            <div className="col-md-6 col-12">
              <div className="mb-3">
                <label htmlFor="fullName" className="form-label">
                  Full Name as shown on government ID/ Passport
                  <span className="input_span">&nbsp;(Required)</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="fullName"
                  placeholder="Name"
                  value={formData.fullName}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="col-md-6 col-12">
              <div className="mb-3">
                <label htmlFor="nationality" className="form-label">
                  Nationality <span className="input_span">(Required)</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="nationality"
                  placeholder="Nationality"
                  value={formData.nationality}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="col-md-6 col-12">
              <div className="mb-3">
                <label htmlFor="profession" className="form-label">
                  Profession <span className="input_span">(Required)</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="profession"
                  placeholder="Profession"
                  value={formData.profession}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="col-md-6 col-12">
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email Address <span className="input_span">(Required)</span>
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                {formData.emailError && (
                  <div className="error pt-2" style={{ color: "#fe0000" }}>
                    Invalid email
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-6 col-12">
              <div className="mb-3">
                <label htmlFor="phoneNumber" className="form-label">
                  Phone Number <span className="input_span">(Required)</span>
                </label>
                <PhoneInput
                  country={"us"}
                  value={formData.phoneNumber}
                  onChange={handlePhoneChange}
                  inputProps={{
                    name: "phoneNumber",
                    required: true,
                    autoFocus: true,
                  }}
                />
                {phoneError && (
                  <div className="error pt-2" style={{ color: "#fe0000" }}>
                    Please enter a valid phone number
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-6 col-12">
              <div className="mb-3">
                <label htmlFor="cityOfResidence" className="form-label">
                  City of Residence
                  <span className="input_span" style={{ color: "red" }}>
                    (Required)
                  </span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="cityOfResidence"
                  placeholder="City of Residence"
                  value={formData.cityOfResidence}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="col-12">
              <div className="mb-3">
                <label htmlFor="comments" className="form-label">
                  Comments
                </label>
                <textarea
                  className="form-control"
                  id="comments"
                  rows="5"
                  value={formData.comments}
                  onChange={handleChange}
                  style={{
                    padding: "12px",
                    borderRadius: "8px",
                    border: "1px solid #ccc",
                  }}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-md-3 col-12 mt-5 mb-4">
              <button
                type="submit"
                className="button-33 w-100 rounded pt-2 pb-2"
              >
                Become a Franchisee
              </button>
            </div>
          </div>
        </form>
      </div>

      <section className="gradient-custom">
        <div className="container my-5 py-5">
          <h2 className="section_section_h2 text-center text-capitalize text-dark mt-3">
            reviews
          </h2>
          <div className="row d-flex justify-content-center">
            <div className="col-md-12 col-10">
              <div className="text-center mb-4 pb-2">
                <i className="fas fa-quote-left fa-3x text-white"></i>
              </div>
              <div className="card">
                <div className="card-body px-4 py-3 py-lg-5">
                  {/* Carousel */}
                  <MDBCarousel showIndicators interval={5000} fade>
                    {/* Single item 1 */}
                    <MDBCarouselItem
                      className="w-100 d-block"
                      itemId={1}
                      src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp"
                      alt="Mark Avatar"
                    >
                      <div className="row d-flex justify-content-center">
                        <div className="col-lg-10 col-xl-8">
                          <div className="row">
                            <div className="col-lg-4 col-12 text-center d-flex align-items-center text-md-start">
                              <img
                                src={ProfileAvatar}
                                alt="Mark Avatar"
                                className="rounded-4"
                                width="150"
                                height="150"
                              />
                            </div>
                            <div className="col-lg-8 col-12 text-center text-lg-start mx-auto mx-lg-0">
                              <h4 className="mb-4">Mark (Franchisee)</h4>
                              <p className="mb-0 pb-0 pb-lg-3">
                                "Investing in a CSCPS franchise has been one of
                                the smartest business decisions I’ve ever made.
                                The model is unique—blending education,
                                healthcare, and AI—and the demand is immense.
                                CSCPS is not just a school; it’s a global
                                movement. The support I’ve received from the
                                team has been excellent, and the growth
                                potential is limitless."
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </MDBCarouselItem>

                    {/* Single item 2 */}
                    <MDBCarouselItem
                      className="w-100 d-block"
                      itemId={2}
                      src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp"
                      alt="Rebecca Avatar"
                    >
                      <div className="row d-flex justify-content-center">
                        <div className="col-lg-10 col-xl-8">
                          <div className="row">
                            <div className="col-lg-4 col-12 text-center d-flex align-items-center text-md-start">
                              <img
                                src={ProfileAvatar}
                                alt="Rebecca Avatar"
                                className="rounded-4"
                                width="150"
                                height="150"
                              />
                            </div>
                            <div className="col-lg-8 col-12 text-center text-lg-start mx-auto mx-lg-0">
                              <h4 className="mb-4">
                                Rebecca (Franchisee & Business Owner)
                              </h4>
                              <p className="mb-0 pb-0 pb-lg-3">
                                "The CSCPS franchise opportunity is unlike
                                anything else in the market. You’re not just
                                investing in education, you’re investing in the
                                future. The Ph.D. labs, AI integration, and
                                multi-tier education model have made this a
                                highly profitable and fulfilling venture for me.
                                I’m proud to be part of something that is truly
                                changing how we think about education and
                                healthcare."
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </MDBCarouselItem>

                    {/* Single item 3 */}
                    <MDBCarouselItem
                      className="w-100 d-block"
                      itemId={3}
                      src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp"
                      alt="Samuel Avatar"
                    >
                      <div className="row d-flex justify-content-center">
                        <div className="col-lg-10 col-xl-8">
                          <div className="row">
                            <div className="col-lg-4 col-12 text-center d-flex align-items-center text-md-start">
                              <img
                                src={ProfileAvatar}
                                alt="Samuel Avatar"
                                className="rounded-4"
                                width="150"
                                height="150"
                              />
                            </div>
                            <div className="col-lg-8 col-12 text-center text-lg-start mx-auto mx-lg-0">
                              <h4 className="mb-4">
                                Samuel (Investor & Franchisee)
                              </h4>
                              <p className="mb-0 pb-0 pb-lg-3">
                                "What sets CSCPS apart is its vision for the
                                future of education. As a franchisee, I’ve seen
                                firsthand how their innovative approach to
                                integrating artificial intelligence into
                                learning attracts students and generates
                                incredible returns. The Ph.D. labs are a
                                game-changer. If you’re looking for a business
                                that’s both profitable and impactful, this is
                                it."
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </MDBCarouselItem>

                    {/* Single item 4 */}
                    <MDBCarouselItem
                      className="w-100 d-block"
                      itemId={4}
                      src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp"
                      alt="Linda Avatar"
                    >
                      <div className="row d-flex justify-content-center">
                        <div className="col-lg-10 col-xl-8">
                          <div className="row">
                            <div className="col-lg-4 col-12 text-center d-flex align-items-center text-md-start">
                              <img
                                src={ProfileAvatar}
                                alt="Linda Avatar"
                                className="rounded-4"
                                width="150"
                                height="150"
                              />
                            </div>
                            <div className="col-lg-8 col-12 text-center text-lg-start mx-auto mx-lg-0">
                              <h4 className="mb-4">
                                Linda (Franchisee & Education Enthusiast)
                              </h4>
                              <p className="mb-0 pb-0 pb-lg-3">
                                "Being a franchisee with CSCPS has given me the
                                chance to contribute to a real education
                                revolution. The AI-driven programs and focus on
                                developing future healthcare leaders are what
                                drew me in. The best part is that you’re not
                                just part of a franchise—you’re part of a vision
                                that’s transforming global education and
                                healthcare."
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </MDBCarouselItem>

                    {/* Single item 5 */}
                    <MDBCarouselItem
                      className="w-100 d-block"
                      itemId={5}
                      src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp"
                      alt="Anthony Avatar"
                    >
                      <div className="row d-flex justify-content-center">
                        <div className="col-lg-10 col-xl-8">
                          <div className="row">
                            <div className="col-lg-4 col-12 text-center d-flex align-items-center text-md-start">
                              <img
                                src={ProfileAvatar}
                                alt="Anthony Avatar"
                                className="rounded-4"
                                width="150"
                                height="150"
                              />
                            </div>
                            <div className="col-lg-8 col-12 text-center text-lg-start mx-auto mx-lg-0">
                              <h4 className="mb-4">Anthony (Franchisee)</h4>
                              <p className="mb-0 pb-0 pb-lg-3">
                                "CSCPS is at the forefront of where education is
                                going. Their business model allows you to open
                                Ph.D. labs, which positions you not only as an
                                educational leader but as an innovator. The
                                returns on investment have been fantastic, but
                                more importantly, I feel like I’m part of
                                something bigger. The support and resources they
                                provide have made the franchise experience
                                seamless."
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </MDBCarouselItem>

                    {/* Single item 6 */}
                    <MDBCarouselItem
                      className="w-100 d-block"
                      itemId={6}
                      src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp"
                      alt="Elizabeth Avatar"
                    >
                      <div className="row d-flex justify-content-center">
                        <div className="col-lg-10 col-xl-8">
                          <div className="row">
                            <div className="col-lg-4 col-12 text-center d-flex align-items-center text-md-start">
                              <img
                                src={ProfileAvatar}
                                alt="Elizabeth Avatar"
                                className="rounded-4"
                                width="150"
                                height="150"
                              />
                            </div>
                            <div className="col-lg-8 col-12 text-center text-lg-start mx-auto mx-lg-0">
                              <h4 className="mb-4">Elizabeth (Investor)</h4>
                              <p className="mb-0 pb-0 pb-lg-3">
                                "CSCPS offers a truly unique business
                                opportunity. The multi-disciplinary approach,
                                coupled with the emphasis on AI and real-world
                                applications, has made this an attractive
                                investment for me. The franchise model is well
                                thought out, and the potential for growth is
                                enormous. This is not just another education
                                franchise; it’s the future of how people will
                                learn and lead."
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </MDBCarouselItem>
                  </MDBCarousel>
                </div>
              </div>
              <div className="text-center mt-4 pt-2">
                <i className="fas fa-quote-right fa-3x text-white"></i>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FranchisingLandingPage;
